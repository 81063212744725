<template>
    <div> 
        <el-row style="text-align:end" class="mb-3">
            <el-col :span="24" >
              <el-button type="primary" class="white"  v-print="'#printContent'"><i class="flaticon2-print white" aria-hidden="true"></i> Print</el-button>
              <el-button type="primary" class="white" @click="printDocument()"><i class="fas fa-file-pdf white"></i> PDF</el-button>
               <download-csv                             
                  :data   = "excell_data"
                  name    = "Transaction_Report.csv" style="float:right;margin-left:5px;" >
                 <el-button type="primary" class="white"><b-icon icon="file-earmark-excel" class="white"></b-icon> Excel</el-button>                       
              </download-csv>
              
            </el-col>
          </el-row>
       <el-card class="box-card">
        <div slot="header" class="clearfix">
        
            <div><h2>{{tittle}}</h2></div>
            <div>
                <el-form ref="form" label-width="120px">
                   
                         <el-col :span="5">
                            <el-form-item label="Starting Date">
                                <el-date-picker type="date" placeholder="start Date" v-model="start_date" style="width: 100%;margin-left: -26px;"></el-date-picker>
                            </el-form-item>
                         </el-col>
                         <el-col :span="5" >
                            <el-form-item label="Ending Date">
                                <el-date-picker type="date" placeholder="End Date" v-model="end_date" style="width: 100%; margin-left: -30px;" ></el-date-picker>
                            </el-form-item>
                         </el-col>
                         <el-col :span="5">
                             <el-button type="primary" icon="el-icon-search" size="mini" @click="getPaymentsDate()">Search</el-button>
                         </el-col> 
                         <el-col :span="9">
                           <el-button  v-if="show == true" icon="el-icon-caret-top" size="mini" style="float: right; padding: 5px;" @click="hideMenu"> Hide Options</el-button>
                           <el-button  v-else icon="el-icon-caret-bottom" size="mini" style="float: right; padding: 5px;" @click="showMenu"> Show Options</el-button>                                                      
                         </el-col>                   
                </el-form>
            </div>
        </div>   
        <div v-if="show == true">
          <el-row class="mb-5 mt-3">
            <el-col :span="4" class="align">
                <div><i class="el-icon-s-promotion button_icon" ></i></div>
                <label class="button_label">Select Lane(s)</label>
                <div><el-button type="primary" class="report_btn">Lanes</el-button></div>
            </el-col>
              <el-col :span="4" class="align">
                <div><i class="el-icon-s-custom button_icon"></i></div>
                <label class="button_label">Select Employee(s)</label>
                <div><el-button type="primary" class="report_btn" @click="selectEmployee()">Employees</el-button></div>
            </el-col>
              <el-col :span="4" class="align">
                <div><i class="el-icon-s-finance button_icon"></i></div>
                <label class="button_label">Select Pay Method(s)</label>
                <div><el-button type="primary" class="report_btn" @click="selectPaymenetMethod()">Payment Methods</el-button></div>
            </el-col>
              <el-col :span="4" class="align">
                <div><i class="el-icon-document-copy button_icon"></i></div>
                <label class="button_label">Select Field(s)</label>
                <div><el-button type="primary" class="report_btn" @click="selectFields()">Fields</el-button></div>
            </el-col>
              <el-col :span="4" class="align">
                <div><i class="el-icon-s-operation button_icon"></i></div>
                <label class="button_label">Select Channel(s)</label>
                <div><el-button type="primary" class="report_btn">Channels</el-button></div>
            </el-col>
              <el-col :span="4" class="align">
                <div><i class="el-icon-collection button_icon"></i></div>
                <label class="button_label" >ARAG</label>
                <div><el-button type="primary" class="report_btn">View</el-button></div>
            </el-col>
          </el-row>
        </div>     
        </el-card>
        <div >        
          <div class="card_div">
            <el-row class="el_header">
              <el-col :span="24"><h5 style="color:#ffff">Summary</h5></el-col>
            </el-row>
            <el-row style="padding:15px;">
              <el-col :span="11">
                <h5>Sales Summary</h5>
                <hr/>
                  <template>
                      <table width="100%">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(sales,index) in sales_summary" :key="index">
                          <td >Total Transaction</td>
                          <td class="transaction_ht">{{sales.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(sales.totals).toFixed(2)}}</td>
                        </tr>
                      </table>                   
                    </template>
              </el-col>
               <el-col :span="11" :offset="2">
                  <h5>Payment Method Summary</h5>
                  <hr/>
                  <template>
                     <table width="100%">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(payment,index) in payment_method_summary" :key="index">
                          <td >{{payment.payment_method}}</td>
                          <td class="transaction_ht">{{payment.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(payment.total).toFixed(2)}}</td>
                        </tr>
                      </table>   
                    </template>
              </el-col>
            </el-row>
            <el-row style="padding:15px;">
              <el-col :span="11" >
                <h5>Refund Summary</h5>
                <hr/>
                 <template>
                    <table width="100%" v-if="refund_summary.length>0">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(refund,index) in refund_summary" :key="index">
                          <td >Total Transaction</td>
                          <td class="transaction_ht">{{refund.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(refund.total).toFixed(2)}}</td>
                        </tr>
                      </table> 
                  </template>
              </el-col>
              <el-col :span="11" :offset="2">
                <h5>A/R Payment Received Summary</h5>
                <hr/>
              </el-col>
            </el-row>
          </div>

          <div class="card_div">
            <el-row class="el_header" style="color:#ffff">
              <el-col :span="24"><h5 >Report Results</h5></el-col>              
            </el-row>
             <div class="row">
          <div class="col-sm-12">
             <el-table :data="transaction_list" border element-loading-text="Loading" style="width:auto !important; padding:13px !important;" v-loading="loading" >              
                <el-table-column label="Date"  width="120" v-if="showAndHideColumn('date')">
                  <template slot-scope="scope">
                    {{scope.row.created_at|formatDate('DD MMM YYYY')}}
                  </template>
                </el-table-column>
                 <el-table-column label="Order ID"  width="120" v-if="showAndHideColumn('order_id')">
                  <template slot-scope="scope">
                    {{scope.row.invoice.order.order_number}}
                  </template>
                </el-table-column>
                  <el-table-column label="Transaction TYpe"  width="150" v-if="showAndHideColumn('transaction_type')">
                  <template slot-scope="scope">
                   <span>{{scope.row.transaction_type}}</span>
                  </template>
                  </el-table-column>
                <el-table-column label="Total"  width="120" v-if="showAndHideColumn('total')">
                    <template slot-scope="scope">
                      ${{scope.row.due_amount}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Payment Method"  v-if="showAndHideColumn('payment_method')" >
                    <template slot-scope="scope">
                      {{scope.row.payment_method}}
                    </template>
                  </el-table-column>
                    <el-table-column label="Customer ID"  width="120" v-if="showAndHideColumn('customer_id')">
                    <template slot-scope="scope">
                      {{scope.row.customer.customer_id}}
                    </template>
                  </el-table-column>
                    <el-table-column label="Employee Name"  width="200" v-if="showAndHideColumn('employee_name')">
                    <template slot-scope="scope">
                      <span v-if="scope.row.employee">{{scope.row.employee.first_name}}</span> <span v-if="scope.row.employee"> {{scope.row.employee.last_name}}</span>
                    </template>
                  </el-table-column>
               </el-table>
               
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                :select_fields="this.select_fields"
                >
            </el-pagination>
          </div>
        </div>      
       </div>    
      <div  hidden>
        <AllTransaction 
        :salesSummary="this.sales_summary" 
        :paymentMethodSummary="this.payment_method_summary"
        :allTransaction="this.all_transaction_list"
        :refundSummary="this.refund_summary"
        
        id="divToPrint">
        </AllTransaction>
      </div>  
      <div class="transaction" id="printContent">
      <PrintTransaction
        :salesSummary="this.sales_summary" 
        :paymentMethodSummary="this.payment_method_summary"
        :allTransaction="this.all_transaction_list"
        :refundSummary="this.refund_summary"
        :select_fields="this.select_fields"
      >
      </PrintTransaction>
      </div>
        </div>
        
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllTransactionList,getPaymentMethodSummary,getPaymentMethod,getSalesSummary,getRefundSummary} from "@/api/report";
import employee from '@/components/ReportManagement/employee_list';
import payment_method from '@/components/ReportManagement/payment_methods';
import { globalEvents } from "@/utils/globalEvents";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import AllTransaction from '@/components/ReportManagement/transaction_report'
import PrintTransaction from '@/components/ReportManagement/print_transaction_report'
import fields from '@/components/ReportManagement/select_field';

export default {
 name : 'all_transaction_type', 
 data(){

     return {
            tittle : 'All Transaction Types',
            date1:null,
            date2:null,
            show:true,
            pagination:{
              total_rows: 1,
              current_page: 1,
              last_page:null,
              per_page: 10,
            },  
            transaction_list:[],
            payment_method_summary:[],
            start_date:'',
            end_date:'' ,
            select_payment : [], 
            payment_method : [],
            employee_list : [], 
            select_employee : [],
            sales_summary : [],
            refund_summary : [],
            all_transaction_list:[],
            excell_data: [],
            select_fields : [
              'date','order_id','transaction_type','total','payment_method','customer_id','employee_name'
            ]
     }

 },
 components: { AllTransaction , PrintTransaction},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "All Transaction Types" }
    ]);

     globalEvents.$on("SelectPaymentMethod", (added) => {
      if (added) {
        this.select_payment = added; 
        this.fetchData(1);      
      }
    });

     globalEvents.$on("selectEmployee", (added) => {
      if (added) {
        this.select_employee = added;         
        this.fetchData(1);      
      }
    });

     globalEvents.$on("selectField", (added) => {
       this.select_fields = [];
      if (added) {
        this.select_fields = added;         
       // this.fetchData(1);      
      }
    });
  },
  methods: {
     getPaymentMethodSummary(){
      this.loading = true
     
    },
     printDocument() {
     
         
          //get table html
          const pdfTable = document.getElementById('divToPrint');
          //html to pdf format
          var html = htmlToPdfmake(pdfTable.innerHTML);
        
          const documentDefinition = { content: html };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          //pdfMake.createPdf(documentDefinition).open();

          pdfMake.createPdf(documentDefinition).download('TransactionReports.pdf');
          
        
    },
    hideMenu(){
      this.show = false
    },
    showMenu(){
       this.show = true
    },
     convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
     showAndHideColumn(column_name){
        return this.$isColumnShow(column_name, this.select_fields);
      },
     fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        if(this.start_date){          
          params.start_date = this.convert(this.start_date);
        }
        if(this.end_date){
          params.end_date = this.convert(this.end_date);
        }

        if(this.select_payment.length > 0){
          params.select_payment = this.select_payment;
        }
        if(this.select_employee > 0){
          params.select_employee = this.select_employee
        }

         getAllTransactionList(params).then(response => {      
          this.transaction_list = response.data.data.data
          this.all_transaction_list = response.data.allTransaction         
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total 
          this.getExcelData(this.all_transaction_list);         
          this.$set(this.transaction_list, '_showDetails', true)
          this.loading = false        
      });
       getPaymentMethodSummary(params).then(response => {              
          this.payment_method_summary = response.data.data         
          this.loading = false   
      });

      getSalesSummary(params).then(response => {              
          this.sales_summary = response.data.data               
          this.loading = false   
      });

      getRefundSummary(params).then(response => {              
          this.refund_summary = response.data.data               
          this.loading = false   
      });
      
      },
      getExcelData(reportData){
         this.excell_data = [];
          reportData.map((data) => {
             let employee_name = ''
             if( data.employee){
              const first_name = data.employee.first_name != null ? data.employee.first_name : ''
              const last_name = data.employee.last_name != null ? data.employee.last_name : ''
              employee_name = first_name + last_name
             }

              this.excell_data.push({
              "DATE" : this.convert(data.created_at),
              "ORDER ID" : data.invoice.order.order_number,
              "TRANSACTION TYPE" : 'Purchase',
              "TOTAl" : '$'+data.due_amount,
              "PAYMENT METHOD" : data.payment_method,
              "CUSTOMER ID" : data.customer.customer_id,
              "EMPLOYEE NAME" : employee_name
            })
          })
      },
       getPaymentsDate(){
        this.fetchData(1)
      },

      getPaymentMethod(){
        this.loading = true;
          getPaymentMethod().then(response => {  
            console.log(response,'payment');            
            this.payment_method = response.data.data         
            this.loading = false             
        });
      },
       handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      selectEmployee(){       
        this.$modal.show(employee, {    
          selectEmployee : this.select_employee
      }, { draggable: true, transition: 'pop-out', height: 'auto', clickToClose: false, adaptive: true,width: '400px'})
    },  
    selectPaymenetMethod(){       
        this.$modal.show(payment_method, {    
          payment_method:this.payment_method, selectPayment : this.select_payment
      }, { draggable: true, transition: 'pop-out', height: 'auto', clickToClose: false, adaptive: true,width: '400px'})
    },
    selectFields(){
       this.$modal.show(fields, {    
          selectFields : this.select_fields
      }, { draggable: true, transition: 'pop-out', height: 'auto', clickToClose: false, adaptive: true,width: '400px'})
    }  
  },
    created(){
      this.fetchData(1);
      this.getPaymentMethod();
     
    },
}
</script>

<style scoped>
.align {
    text-align: center;
}

.button_icon{
    font-size: 30px;
    color: #409eff;
}

.button_label{
  font-weight:bold;
  font-size: 11px;
}

.report_btn{
    padding: 6px;
    color: white;
    font-size: 12px;
}

.el_header{
    background-color: #72aec6; 
    padding: 8px 0px 5px 15px;
}

.card_div{
  background-color: #ffff; 
  margin-top: 10px;
  border-radius: 4px;
}

.white{
  color: #ffff;
}
.transaction{
  display: none;
}
.total_hd{
    text-align: end;
    padding-right: 15px;
    color: #3699ff;
}
.table_transaction_hd{
    text-align: center;
    padding-right: 15px;
    color: #3699ff;
}
.text_font{
  font-weight: bold;
}
.total_ht{
  text-align: end;
  padding: 5px 14px;
  
}
.transaction_ht{
  text-align: #ededed;
  color: #000;
 
}
.el-table th{
  background: #e4e7eb !important
}
@media print {
  .transaction{
    display: block;
  }
}

</style>