<!-- Employees -->
<template>
<div>
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="el-icon-s-finance" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <template>
                                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox>
                                    <div style="margin: 15px 0;"></div>
                                      <el-checkbox-group v-model="checkedColumns" @change="handleCheckedCitiesChange" style="display:grid">
                                          <el-checkbox v-for="(payment,index) in payment_methods" :label="payment" :key="index" >{{payment}}</el-checkbox>
                                      </el-checkbox-group>                                   
                                </template>
                            </div>
                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-5">
                                <a @click="selectedPayment" class="text-white">
                                    <div class="btn btn-primary btn-sm font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { globalEvents } from '@/utils/globalEvents';
import Form from '@/core/services/api.form.services'

export default {
  name: 'add-brand',
   props: {
   payment_method:{
      type: Array,
      require:true 
   },
   selectPayment:{
     type : Array
   },
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'Select Payment Method(s)'}
      }
	  }
  },
    data() {
    return {
      loading:false,
      checkAll:false,
      isIndeterminate: true,
      form : new Form({
      
      }),
   
      payment_methods : [],     
      submit_btn_text: 'OK',    
        checkedColumns:[]
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product Brands", route: "/product/brands" },
      { title: "Brand" }
    ]);
  },
  methods: {
     closeForm() {
    	if (!this.form.submiting) {
      	globalEvents.$emit('SelectPaymentMethod', false)
      	this.$emit('close')
    	}
	  },
    selectedPayment: function() {
       this.$emit('close'); 
       globalEvents.$emit('SelectPaymentMethod', this.checkedColumns)
      // this.loading=true;
      // this.form.post(this.api_url).then((response) => {
      //   if(response.status){          
      //       this.loading=false;
      //       globalEvents.$emit('BrandCreated', response.data)
      //       this.$emit('close');           
      //       //this.$showResponse('success', response.message);
      //   }
      // })
    },
 

    
    handleCheckedCitiesChange(value) {
     
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.payment_methods.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.payment_methods.length;
        
      },
      handleCheckAllChange(val) {     
          this.checkedColumns = [];
          if(val){
              this.payment_methods.map((column) =>{
                  this.checkedColumns.push(column);                 
              });
          }
          this.isIndeterminate = false;
      },
  },

  created(){
   
    this.payment_method.map((payment)=>{     

      // if(payment.payment_method == 'Credit Card'){
      //    this.payment_methods.push(payment.brand); 
      // }else{
         this.payment_methods.push(payment.payment_method); 
      //}
           
    })

    this.checkedColumns = this.selectPayment;
  
  }

}
</script>

<style scoped>
.el-checkbox__input {
    vertical-align: top;
    margin-top: 3px;
}
</style>

