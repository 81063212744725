import request from '@/core/services/api.services.request'

export function getAllTransactionList(params) {
  return request({
    url: '/payment/transaction/list',
    method: 'get',
    params: params
  })
  
}

export function getPaymentMethodSummary(params) {
    return request({
      url: 'payment/method/summary',
      method: 'get',
      params: params
    })
  }

  export function getPaymentMethod() {
    return request({
      url: 'payment/method',
      method: 'get'      
    })
  }

  export function getPaymentEmployee() {
    return request({
      url: '/payment/employee',
      method: 'get',
    })
  }

  export function getSalesSummary(params) {
    return request({
      url: '/payment/sales/summary',
      method: 'get',
      params: params
    })
  }

  export function getRefundSummary(params) {
    return request({
      url: '/payment/refund/summary',
      method: 'get',
      params: params
    })
  }
  
  