<template>
    <div >
        <span style="width:50%">
            <h5 >Summary</h5>
            <hr/>
            <table class="table table-bordered">
            <thead>
                <tr>
                <th>Method</th>
                <th>Transactions</th>
                <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sales,index) in salesSummary" :key="index">
                    <td>Total Transaction</td>
                    <td>{{sales.transaction}}</td>
                    <td>{{sales.totals}}</td>
                </tr>
            </tbody>
            </table>
        </span>       
          <span style="margin-top:10px">
            <h5 >Payment Method Summary</h5>
            <hr/>
            <table class="table table-bordered">
            <thead>
                <tr>
                <th>Method</th>
                <th>Transactions</th>
                <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sales,index) in paymentMethodSummary" :key="index">
                    <td>{{sales.payment_method}}</td>
                    <td>{{sales.transaction}}</td>
                    <td>{{sales.total}}</td>
                </tr>
            </tbody>
            </table>
        </span>     
         <span style="margin-top:10px">
            <h5 >Refund Summary</h5>
            <hr/>
            <table class="table table-bordered">
            <thead>
                <tr>
                <th>Method</th>
                <th>Transactions</th>
                <th>Total</th>
                </tr>
            </thead>
            <tbody>
             <tr v-for="(sales,index) in refundSummary" :key="index">
                    <td>Total Transaction</td>
                    <td>{{sales.transaction}}</td>
                    <td>{{sales.totals}}</td>
                </tr>
            </tbody>
            </table>
        </span>     
         <span style="margin-top:10px">
            <h5 >A/R Payment Received Summary</h5>
            <hr/>
            <!-- <table class="table table-bordered">
            <thead>
                <tr>
                <th>Method</th>
                <th>Transactions</th>
                <th>Total</th>
                </tr>
            </thead>
            <tbody>
             <tr></tr>
            </tbody>
            </table> -->
        </span>  
        <div style="margin-top:10px">
            <h5 >Report Results</h5>
            <hr/>
            <table class="table table-bordered">
            <thead>
                <tr>
                <th>Date</th>
                <th>Order ID</th>
                <th>Transaction TYpe</th>
                <th>Total</th>
                <th>Payment Method</th>
                <th>Customer ID</th>
                <th>Employee Name</th>
                </tr>
            </thead>
            <tbody>
             <tr v-for="(allsales,index) in allTransaction" :key="index">
                 <td>{{allsales.created_at|formatDate('DD MMM YYYY')}}</td>
                 <td>{{allsales.invoice.order.order_number}}</td>
                 <td>Purchase</td>
                 <td>{{allsales.due_amount}}</td>
                 <td>{{allsales.payment_method}}</td>
                 <td>{{allsales.customer.customer_id}}</td>
                 <td><span v-if="allsales.employee">{{allsales.employee.first_name}}</span> <span v-if="allsales.employee"> {{allsales.employee.last_name}}</span></td>
             </tr>
            </tbody>
            </table>
        </div>            
    </div>
</template>

<script>

export default {
  
    name : 'transaction_report', 
    props: {
        salesSummary :{
        type: Array
        },
        paymentMethodSummary:{
            type:Array
        },
        allTransaction:{
            type:Array
        },
        refundSummary:{
            type:Array
        }
    },
 data(){
     return {
         
     }
 }
    
}
</script>