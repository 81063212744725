<template>
    <div>
       <el-card class="box-card">
            <div class="card_div">
            <el-row class="el_header">
              <el-col :span="24"><h3 >Summary</h3></el-col>
            </el-row>
            <el-row style="padding:15px;">
              <el-col :span="11">
                <h5>Sales Summary</h5>
                <hr/>
                  <template>                
                      <table width="100%">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(sales,index) in salesSummary" :key="index">
                          <td >Total Transaction</td>
                          <td class="transaction_ht">{{sales.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(sales.totals).toFixed(2)}}</td>
                        </tr>
                      </table>    
                    </template>
              </el-col>
               <el-col :span="11" :offset="2">
                  <h5>Payment Method Summary</h5>
                  <hr/>
                  <template>
                     <table width="100%">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(payment,index) in paymentMethodSummary" :key="index">
                          <td >{{payment.payment_method}}</td>
                          <td class="transaction_ht">{{payment.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(payment.total).toFixed(2)}}</td>
                        </tr>
                      </table>  
                    
                    </template>
              </el-col>
            </el-row>
            <el-row style="padding:15px;">
              <el-col :span="11">
                <h5>Refund Summary</h5>
                <hr/>
                 <template>
                    <table width="100%" v-if="refundSummary.length>0">
                        <tr>
                          <th width="50%"></th>
                          <th width="20%" class="table_transaction_hd">Transaction</th>
                          <th width="30%" class="total_hd">Total</th>
                        </tr>
                        <tr v-for="(refund,index) in refundSummary" :key="index">
                          <td >Total Transaction</td>
                          <td class="transaction_ht">{{refund.transaction}}</td>
                          <td class="total_ht">$ {{parseFloat(refund.total).toFixed(2)}}</td>
                        </tr>
                      </table>                 
                  </template>
              </el-col>
              <el-col :span="11" :offset="2">
                <h5>A/R Payment Received Summary</h5>
                <hr/>
              </el-col>
            </el-row>
          </div>
        <div class="card_div">
            <el-row class="el_header">
              <el-col :span="24"><h3>Report Results</h3></el-col>              
            </el-row>
             <div class="row">
          <div class="col-sm-12">
             <el-table :data="allTransaction" border  style="width:auto !important; padding:13px !important;" >              
                <el-table-column label="Date"  width="120" v-if="showAndHideColumn('date')">
                  <template slot-scope="scope">
                    {{scope.row.created_at|formatDate('DD MMM YYYY')}}
                  </template>
                </el-table-column>
                 <el-table-column label="Order ID"  width="120" v-if="showAndHideColumn('order_id')">
                  <template slot-scope="scope">
                    {{scope.row.invoice.order.order_number}}
                  </template>
                </el-table-column>
                  <el-table-column label="Transaction TYpe"  width="150" v-if="showAndHideColumn('transaction_type')">
                  <template slot-scope="scope">
                   <span>{{scope.row.transaction_type}}</span>
                  </template>
                  </el-table-column>
                <el-table-column label="Total"  width="120" v-if="showAndHideColumn('total')">
                    <template slot-scope="scope">
                      ${{scope.row.due_amount}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Payment Method"  width="150" v-if="showAndHideColumn('payment_method')" >
                    <template slot-scope="scope">
                      {{scope.row.payment_method}}
                    </template>
                  </el-table-column>
                    <el-table-column label="Customer ID"  width="120" v-if="showAndHideColumn('customer_id')">
                    <template slot-scope="scope">
                      {{scope.row.customer.customer_id}}
                    </template>
                  </el-table-column>
                    <el-table-column label="Employee Name"  min-width="250" v-if="showAndHideColumn('employee_name')">
                    <template slot-scope="scope">
                      <span v-if="scope.row.employee">{{scope.row.employee.first_name}}</span> <span v-if="scope.row.employee"> {{scope.row.employee.last_name}}</span>
                    </template>
                  </el-table-column>
               </el-table>
          </div>
        </div>      
       </div> 
       </el-card>
    </div>
</template>

<script>

export default {
  
    name: 'print_transaction',
     props: {
        salesSummary :{
        type: Array
        },
        paymentMethodSummary:{
            type:Array
        },
        allTransaction:{
            type:Array
        },
        refundSummary:{
            type:Array
        },
         select_fields : {
           type:Array
         }
    },
    methods: {
      showAndHideColumn(column_name){
        return this.$isColumnShow(column_name, this.select_fields);
      },
    }
}
</script>

<style scoped>

@media print {
  
.align {
    text-align: center;
}

.button_icon{
    font-size: 30px;
    color: #409eff;
}

.button_label{
  font-weight:bold;
  font-size: 11px;
}

.report_btn{
    padding: 6px;
    color: white;
    font-size: 12px;
}

.el_header{
    
    padding: 8px 0px 5px 15px;
}

.card_div{
  background-color: #ffff; 
  margin-top: 10px;
  border-radius: 4px;
}

.white{
  color: #ffff;
}
.transaction{
  display: none;
}
.total_hd{
    text-align: end;
    padding-right: 15px;
    color: #3699ff;
}
.table_transaction_hd{
    text-align: center;
    padding-right: 15px;
    color: #3699ff;
}
.text_font{
  font-weight: bold;
}
.total_ht{
  text-align: end;
  padding: 5px 14px;
  
}
.transaction_ht{
  text-align: #ededed;
  color: #000;
 
}
el-table th{
  background: #e4e7eb !important;
  color:#000
}

}

</style>